<template>
  <div id="app">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
body {
  height: 1500px;
}

* {
  box-sizing: inherit;
  margin: 0;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: var(--html-font-size);
  font-family: var(--font-family);
  line-height: var(--line-height);
  color: var(--color);
  background: var(--background-color);
}

html {
  --header-height: 5rem;
  --font-family: apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  --line-height: 1.5;
}

html {
  --color-accent: rgb(243,128,32);
  --color-accent-2: rgb(248,173,76);
  --color: rgb(29,31,32);
  --background-color: rgb(255, 255, 255);
}

.__container {
  position: relative;
  max-width: 1160px;
  margin: 0 auto;
}

.button {
  cursor: pointer;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  position: relative;
  border: 0;
  background: transparent;
  color: inherit;
  text-decoration: none;
  line-height: 1.3em;
  padding: .55em .9em .65em;
  border-radius: .375em;
  box-shadow: 0 1px 1px rgba(29,31,32,.075),0 .1333em .26667em rgba(29,31,32,.075),0 .2222em .66667em 0 rgba(29,31,32,.075),0 .4444em 1.3333em 0 rgba(29,31,32,.075);
}

.__button_primary {
  background: linear-gradient(25deg, var(--color-accent), var(--color-accent-2));
  color: #fff;
}

.__button_default {
  background: rgb(243, 243, 244);
  box-shadow: none;
}

::selection {
  background: rgb(243,128,32);
}
::-moz-selection {
  background: rgb(243,128,32);
}

.emphasis {
  display: inline-block;
  border-bottom: 5px solid var(--color-accent);
}

hr {
  border: 0;
  height: .25rem;
  background-color: #fe5301;
  background: linear-gradient(25deg, var(--color-accent), var(--color-accent-2));
  border-radius: .25em;
  margin-bottom: .75em;
}
</style>