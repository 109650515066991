<template>
  <footer class="footer">
    <p class="copyright">
      &copy; 2022 Ayman H
    </p>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
.footer {
  margin-top: 8rem;
  padding: 2rem;
}

.copyright {
  text-align: center;
  font-size: .8em;
  color: rgba(29,31,32,.75);
  margin-left: -1.5em;
  margin-right: -1.5em;
}
</style>
