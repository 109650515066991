<template>
  <section class="__hero_section">
    <div class="columns">
      <div class="__column_content">
        <h1 class="__hero_title">
          I'm <div class="emphasis">Ayman H.</div>
          <br>
          I build <em>fast &<br>secure</em> sites.
        </h1>
        <div class="__hero_description">
          <p>Full-stack developer crafting cutting-edge, pixel perfect, screaming fast and secure web apps.</p>
        </div>
        <div class="__hero_actions">
          <div class="__hero_actions_item">
            <router-link to="/" class="button __button_primary">Contact</router-link>
          </div>
          <div class="__hero_actions_item">
            <router-link to="/" class="button __button_default">Projects</router-link>
          </div>
        </div>
        <div class="__hero_key_points">
          <ul class="__hero_bulletpoint_list">
            <li>
              <strong>Cutting-edge</strong>
              technologies used such as React, Vue, Express & Django.
            </li>
            <li>
              <strong>Pixel perfection</strong>
              in designing your dream web app.
            </li>
            <li>
              <strong>Unparalleled performance</strong>
              because who doesn't like screaming fast sites?
            </li>
          </ul>
        </div>
      </div>
      <div class="__column_preview">
        <CodeEditor />
      </div>
    </div>
  </section>
</template>

<script>
import CodeEditor from '@/components/CodeEditor.vue'

export default {
  name: 'Hero',
  components: {
    CodeEditor
  }
}
</script>

<style scoped lang="scss">
  .__hero_section {

    .columns {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5em;
      width: 100%;
    }

    .__column_content {
      width: 100%;
      max-width: 100%;
      margin-right: 25px;
    }

    .__hero_title {
      position: relative;
      font-size: 3.75em;
      line-height: 1.05;
      font-weight: 700;
      margin-bottom: 1rem;
      letter-spacing: -.01em;
    }

    .__hero_description {
      font-size: 1.5em;
      letter-spacing: -.01em;
      margin-bottom: 1.25em;
      width: 18em;
      max-width: 100%;
    }

    .__hero_actions {
      font-size: 1.333em;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 2em;
    }

    .__hero_actions_item:not(:last-child) {
      margin-right: 1em;
    }

    .__hero_key_points {
      text-align: left;
      font-size: .95em;
    }

    .__hero_bulletpoint_list {
      padding: 0;
      list-style: none;
      
      li:not(:last-child) {
        margin-bottom: 0.75em;
      }

      li {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%231d1f20" d="M8,3A2,2 0 0,0 6,5V9A2,2 0 0,1 4,11H3V13H4A2,2 0 0,1 6,15V19A2,2 0 0,0 8,21H10V19H8V14A2,2 0 0,0 6,12A2,2 0 0,0 8,10V5H10V3M16,3A2,2 0 0,1 18,5V9A2,2 0 0,0 20,11H21V13H20A2,2 0 0,0 18,15V19A2,2 0 0,1 16,21H14V19H16V14A2,2 0 0,1 18,12A2,2 0 0,1 16,10V5H14V3H16Z" /></svg>') no-repeat;
        background-size: 1.5em 1.5em;
        padding-left: 2em;
      }
    }
  }
</style>
