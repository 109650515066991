<template>
<div style="display: flex; flex-direction: column">
  <p class="from-me">Try me, I'm interactive!</p>
  <div class="__editor_container">
    <div class="editor">
      <div class="__editor_window">
        <div class="__editor_control_label">
          <div class="__editor_window_controls">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="__editor_window_title"></div>
        </div>

        <div class="__editor_side_nav">
          <img src="@/assets/1.png">
          <img src="@/assets/2.png">
          <img src="@/assets/3.png">
          <img src="@/assets/4.png">
          <img src="@/assets/5.png">
        </div>

        <div class="__editor_viewport">
          <div class="__c_def">&#60;</div>template<div class="__c_def">&#62;</div>
          <br>
          &nbsp;&nbsp;<div class="__c_def">&#60;</div>div <div class="__c_teal">id</div><div class="__c_def">="</div><div class="__c_orange">app</div><div class="__c_def">"&#62;</div>
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;<div class="__c_def">&#60;</div>Header <div class="__c_def">/&#62;</div>
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;<div class="__c_def">&#60;</div>router-view <div class="__c_def">/&#62;</div>
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;<div class="__c_def">&#60;</div>Footer <div class="__c_def">/&#62;</div>
          <br>
          &nbsp;&nbsp;<div class="__c_def">&#60;/</div>div<div class="__c_def">&#62;</div>
          <br>
          <div class="__c_def">&#60;/</div>template<div class="__c_def">&#62;</div>
          <br>
          <br>
          <div class="__c_def">&#60;</div>script<div class="__c_def">&#62;</div>
          <br>
          import <div class="__c_white">Header</div> from <div class="__c_yellow">'@/components/Header.vue'</div>
          <br>
          import <div class="__c_white">Footer</div> from <div class="__c_yellow">'@/components/Footer.vue'</div>
          <br>
          <br>
          export default <div class="__c_yellow">{</div>
          <br>
          &nbsp;&nbsp;<div class="__c_white">components:</div> <div class="__c_pink">{</div>
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;<div class="__c_white">Header,</div>
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;<div class="__c_white">Footer</div>
          <br>
          &nbsp;&nbsp;<div class="__c_pink">}</div>
          <br>
          <div class="__c_yellow">}</div>
          <br>
          <div class="__c_def">&#60;/</div>script<div class="__c_def">&#62;</div>
          <br>
          <br>

          <div class="__c_def">&#60;</div>style <div class="__c_teal">scoped lang<div class="__c_def">="</div><div class="__c_yellow">scss</div><div class="__c_def">"</div></div><div class="__c_def">&#62;</div>
          <br>
          * <div class="__c_def">{</div>
          <br>
          &nbsp;&nbsp;<div class="__c_white">box-sizing</div><div class="__c_def">:</div> <div class="__c_teal">inherit</div><div class="__c_def">;</div>
          <br>
          &nbsp;&nbsp;<div class="__c_white">margin</div><div class="__c_def">:</div> <div class="__c_purple">0</div><div class="__c_def">;</div>
          <br>
          <div class="__c_def">}</div>
          <br>
          <div class="__c_def">&#60;/</div>style<div class="__c_def">&#62;</div>
        </div>

        <div class="__editor_bottom_details">
          <div class="__editor_window_title"></div>
          <div class="__editor_window_title"></div>
          <div class="__editor_window_title"></div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'CodeEditor'
}
</script>

<style scoped lang="scss">
p.from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff; 
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
  margin-bottom: 1em;
}

p.from-me::before,
p.from-me::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.__editor_container {
  width: 32em;
  height: 28em;
  display: flex;
  flex-direction: column;
  // transform-style: preserve-3d;
  // transform: perspective(6cm) rotateY(-8deg);

  .editor {
    border-radius: 0.5em;
    height: 100%;
    width: 100%;
    overflow: hidden;
    transform: translate3d(calc(var(--x-offset) + 4em*var(--n)),calc(6em + -4.25em*var(--n)),calc(4em + -10em*var(--n))) rotate3d(0,-1,0,20deg);
    box-shadow: 0 0 0 1px rgb(0 0 0 / 7%), 2em 4em 6em -2em rgb(0 0 0 / 8%), 1em 2em 3.5em -2.5em rgb(0 0 0 / 8%);

    .__editor_window {
      position: relative;
      background: rgb(39,39,39);
      border-radius: .5em;
      height: 100%;
      width: 100%;
      overflow: hidden;

      .__editor_control_label {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        height: 2em;
        background: rgb(80,80,80);
        border-radius: 0.5em 0.5em 0 0;
        box-shadow: 0 1px rgb(0 0 20 / 10%);

        .__editor_window_controls {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0.75em;
          margin-top: auto;
          margin-bottom: auto;
          height: 8px;
          display: flex;

          div {
            height: 10px;
            width: 10px;
            border-radius: 50%;
          }

          div + div {
            margin-left: 0.25em;
          }

          div:nth-child(1) {
            background: rgb(238, 92, 85);
          }

          div:nth-child(2) {
            background: rgb(248, 189, 69);
          }

          div:nth-child(3) {
            background: rgb(94, 202, 66);
          }
        }

        .__editor_window_title {
          height: 12px;
          width: 50%;
          margin: 0 auto;
          border-radius: 0.5em;
          background: rgb(53, 53, 53);
        }
      }

      .__editor_side_nav {
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: column;
        z-index: 1;
        height: 100%;
        width: 3.5em;
        padding: .75em .5em;
        background: rgb(53,53,53);

        img:not(:last-child) {
          margin-bottom: 1.25em;
        }
      }

      .__editor_viewport {
        margin-left: 3.5em;
        padding: .75em;
        color: rgb(255, 101, 122);
        font-family: consolas;
        overflow-y: scroll;
        max-height: 24.7em;


        .__c_def {
          display: inline-block;
          color: rgb(136, 141, 148);
        }

        .__c_white {
          display: inline-block;
          color: rgb(255, 255, 255);
        }

        .__c_yellow {
          display: inline-block;
          color: rgb(255, 215, 0);
        }

        .__c_pink {
          display: inline-block;
          color: rgb(218, 112, 214);
        }

        .__c_teal {
          display: inline-block;
          color: rgb(126, 209, 187);
        }

        .__c_orange {
          display: inline-block;
          color: rgb(248, 112, 64);
        }

        .__c_purple {
          display: inline-block;
          color: rgb(195, 155, 175);
        }
      }

      .__editor_viewport::-webkit-scrollbar {width: 15px; }
      .__editor_viewport::-webkit-scrollbar-track {background: 0;}
      .__editor_viewport::-webkit-scrollbar-thumb {background: rgb(96, 96, 96); border-radius: 50px; border: 4px solid rgb(39,39,39);}
      .__editor_viewport::-webkit-scrollbar-thumb:hover {background: rgb(144, 144, 144);}

      .__editor_bottom_details {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        height: 1.25em;
        width: 100%;
        bottom: 0;
        padding: 0 10px;
        background: rgb(80,80,80);

        .__editor_window_title {
          height: 6px;
          border-radius: 0.5em;
          background: rgb(53, 53, 53);
          margin: 0 .75em;
        }

        .__editor_window_title:nth-child(1) {
          width: 1em;
        }

        .__editor_window_title:nth-child(2) {
          width: 5em;
        }

        .__editor_window_title:nth-child(3) {
          width: 100%;
        }
      }
    }
  }
}
</style>
