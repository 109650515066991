<template>
  <header :class="['header', { '__header_sticky': !showNavbar }]">
    <nav class="nav">
      <router-link to="/" class="__nav_link __nav_item">
        <img src="@/assets/logo.png" alt="Ayman H.">
      </router-link>

      <div class="__nav_links">
        <div class="__nav_item">
          <router-link to="/" class="button __button_default">Github</router-link>
        </div>

        <div class="__nav_item">
          <router-link to="/" class="button __button_primary">Contact</router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  
  data() {
    return {
      showNavbar: true,
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll () {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        this.showNavbar = false
      } else {
        this.showNavbar = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  position: sticky;
  top: 2.5rem; left: 0;
  z-index: 10;
  width: 100%;
  margin-top: 1em;
  padding-bottom: 2.5rem;
  max-width: 1160px;
  margin: 0 auto;
  transition: padding 350ms ease, box-shadow 350ms ease;
}

.__header_sticky {
  border-radius: .375em;
  padding: 1.25em 1em;
  box-shadow: 0 1px 1px rgba(29,31,32,.075),0 .1333em .26667em rgba(29,31,32,.075),0 .2222em .66667em 0 rgba(29,31,32,.075),0 .4444em 1.3333em 0 rgba(29,31,32,.075);
  background: rgba(255, 255, 255, .9);
  backdrop-filter: blur(2px);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .__nav_link {
    display: block;
    color: inherit;
    text-decoration: none;

    img {
      display: block;
      width: 125px;
      margin: 0;
    }
  }

  .__nav_item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  
  .__crescent_logo {
    display: block;
    height: auto;
    width: 8em;

    svg {
      display: block;
    }
  }

  .__nav_links {
    display: flex;
    height: 100%;

    .__nav_item:not(:last-child) {
      margin-right: 0.75em;
    }
  }
}

</style>
