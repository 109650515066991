<template>
  <div class="line __line_down">
    <div class="__container">
      <div class="__line_icon">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LineUp',
}
</script>

<style scoped lang="scss">
.line {
  position: relative;

  .__line_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 10rem;
    border: 1px solid #e2e6eb;
    background: var(--background-color);
    z-index: 5;
    margin-left: auto;

    svg path {
      opacity: .5;
      color: var(--color-accent);
    }
  }
}

.line:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 1px solid #e2e6eb;
}
.__line_down:after {
  -webkit-transform: skewY(7deg);
  transform: skewY(7deg);
}
</style>
